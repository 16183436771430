module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dmitrii Frolov","short_name":"Dmitrii Frolov","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"/Users/dmitriifrolov/Desktop/portfolio/portfolio/src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2be4b4c7a5c0ff8c90d061d93e771f80"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
